<template>
  <div>
    <p class="name">组串式逆变器 (MPPT)应用</p>
    组串式逆变器通过独立追踪多组光伏组串的最大功率点，将其转换后并入交流电网。功率一般在100KW以下。优点包括高发电量、灵活组件配置、便捷安装和维护。
    MPPT技术监测并追踪光伏电池组的输出，调整逆变器工作点以保持最大功率，提高太阳能系统效率。
    <br/>
    <el-image
        src="http://img.sicred.cn/assets/img/application/page21-1.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page21-1.png']"
        /><br/>
    组串式逆变器系统的主要组件：光伏电池板串或阵列、带MPPT功能的DC-DC升压转换器、DC-Link电容器和逆变器(DC-AC
    转换器) <br /><br />
    DC-DC级实现两个功能： <br />
    &nbsp;&nbsp;● 将PV 串的输出电压提升至DC-Link工作电压水平； <br />
    &nbsp;&nbsp;● 实施 MPPT(最大功率点跟踪)功能，使光伏面板在不同环境和太阳辐照度下产生的功率最大化 <br /><br />
    逆变器负责将直流电转换为交流电，用于正常的住宅用电或并网 <br />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.name {
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
img {
  display: block;
  padding: 30px;
  width: 100%;
  height: auto;
} @media (max-width: 768px) {
    .name{
        font-size: 20px;
    }
    img {
        padding: 10px 0;
    }
 }
</style>
