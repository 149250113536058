<template>
  <div>
    <p class="name">5G通信服务器电源&工业电源领域应用</p>
    <br/>
    <el-image
        src="http://img.sicred.cn/assets/img/application/page11-1.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page11-1.png']"
        /><br/>
    至信微建议用料规格:<br/>
    &nbsp;&nbsp;● SiC SBD: 650V/10A~20A ：SDC10065T2AS、SDC20065T2AS<br/>
    &nbsp;&nbsp;● SiC SBD 1200V/2A~5A ： SDC02120T2AS、 SDC05120T2AS<br/>
    &nbsp;&nbsp;● SiC MOSFET: 650V/25mΩ & 650V/40mΩ ：SMC25N065DT4AS、SMC40N065T4AS<br/>
    &nbsp;&nbsp;● SiC MOSFET: 1200V/80mΩ &1200V/160mΩ : SMC80N120T4AA、SMC160N120T4AS<br/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
   
  },
  data () {
    return {
     
    }
  }
}
</script>

<style scoped>
.name{
  width: 100%;
 font-size: 30px;
 font-weight: bold;
 text-align: center;
 margin-bottom: 40px;
}
img{
  display: block;
  padding: 30px ; 
   width: 100%;
  height: auto;
 } @media (max-width: 768px) {
    .name{
        font-size: 20px;
    }
    img {
        padding: 10px 0;
    }
 }
</style>
