<template>
  <section class="product-wrapper">
    <div class="container">
      <Crumbs name="产品应用" />
      <div class="flex">
        <el-menu
          :default-active="menuActive"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @select="handleMenu"
          @close="handleClose"
        >
          <el-submenu index="1">
            <template slot="title">
              <span class="menuTitle">通信服务&工业</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1"
                >5G通信服务器电源&数据中心<br/>&工业电源</el-menu-item
              >
            </el-menu-item-group>
            <!-- <el-menu-item-group>
              <el-menu-item index="1-2"
                >5G通信服务器电源&工业电源领域应用</el-menu-item
              >
            </el-menu-item-group> -->
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span class="menuTitle">光伏/储能逆变器</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="2-1">组串式逆变器（MPPT）应用</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="2-2"
                >单相组串式逆变器（Inverter）应用</el-menu-item
              >
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="2-3"
                >三相组串式逆变器（Inverter）应用</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span class="menuTitle">新能源/新能源汽车</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="3-1">直流充电桩</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="3-2">车载电源OBC& DC-DC</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="3-3">电动空调压缩机控制器</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="3-4">电动主驱动逆变器</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
        <div class="product-content">
          <div class="richText">
            <!-- 富文本内容 -->
            <page11 v-if="menuActive == '1-1'" />
            <page12 v-if="menuActive == '1-2'" />
            <page21 v-if="menuActive == '2-1'" />
            <page22 v-if="menuActive == '2-2'" />
            <page23 v-if="menuActive == '2-3'" />
            <page31 v-if="menuActive == '3-1'" />
            <page32 v-if="menuActive == '3-2'" />
            <page33 v-if="menuActive == '3-3'" />
            <page34 v-if="menuActive == '3-4'" />
          </div>
          <div class="form">
            <ApplicationForm />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Crumbs from "@/components/Crumbs";
import page11 from "@/page/application/page1-1";
import page12 from "@/page/application/page1-2";
import page21 from "@/page/application/page2-1";
import page22 from "@/page/application/page2-2";
import page23 from "@/page/application/page2-3";
import page31 from "@/page/application/page3-1";
import page32 from "@/page/application/page3-2";
import page33 from "@/page/application/page3-3";
import page34 from "@/page/application/page3-4";
import ApplicationForm from "@/components/ApplicationForm";
export default {
  name: "HelloWorld",
  components: { Crumbs, page11, page12, page21, page22 , page23 , page31 , page32, page33, page34,ApplicationForm },
  props: {
    msg: String,
  },
  data() {
    return {
      menuActive: "1-1",
    };
  },
  mounted(){
    if(this.$route.query.menuActive){
      this.menuActive = this.$route.query.menuActive;
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleMenu(key) {
      this.menuActive = key;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.product-wrapper {
  background-color: #fff;
  padding-bottom: 40px;
}
.el-menu-vertical-demo {
  min-width: 300px;
  
  height: 100%;
  margin-right: 30px;
  border: 1px solid #eee;
  border-radius: 4px;
}
::v-deep.el-submenu .el-menu-item {
  padding: 13px 32px !important;
  line-height: 24px;
  height: auto;
}
::v-deep .el-menu-item {
  font-size: 14px;
}
::v-deep .el-menu-item.is-active {
  color: #fff;
  background-color: #4fbfe9;
  border-left: 5px solid #284777;
  padding: 13px 32px 13px 27px !important;
}
.menuTitle {
  font-size: 18px;
  font-weight: 700;
}
.flex {
  display: flex;
}
@media (max-width: 768px) {
    .flex{
        display:block;
    }
    .el-menu-vertical-demo{
        width: 100%;
        margin-bottom: 20px;
    }
    .menuTitle {
        font-size: 14px; 
    }
    ::v-deep .el-menu-item.is-active{
        overflow: hidden;
        text-align: center;
        border-left:0;
        flex: 1;
    }
    .form {
     margin-bottom: 20px;
    }
}
.product-content {
  padding: 0;
  font-size: 16px;
  .richText {
    margin-bottom: 60px;
  }
}
.form {
  margin-bottom: 60px;
}
</style>
