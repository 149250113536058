<template>
  <div>
    <p class="name">三相组串式逆变器（Inverter）应用</p>
    <el-image
        src="http://img.sicred.cn/assets/img/application/page23-1.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page23-1.png']"
        /><br/>
三相组串式多用于工商业分布式和地面电站，单模块通常为30kW，最大可到350kW。<br/>
辅助电源输入电压超1000V，用1700V SiC MOS的单管反激替代800V Si MOS的双管反激，大幅降低BOM成本，电路更简单，驱动更容易。<br/>
30kW以下以单管功率器件为主，30kW以上有使用模块功率器件<br/>
D1、D2通常使用SiC JBS，Q1、Q2推荐SiC MOSFET替代IGBT <br/><br/>
    至信微建议用料规格:<br/>
    &nbsp;&nbsp;● SiC SBD: 1200V/20A~40A :SDC20120T2AA 、SDC40120T3AA<br/>
    &nbsp;&nbsp;● SiC MOSFET: 1200V/40mΩ&80mΩ及1700V/1Ω: SMC40N120T3AA 、SMC80N120T3AA、 SMC1R0N170HAS<br/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
   
  },
  data () {
    return {
     
    }
  }
}
</script>

<style scoped>
.name{
  width: 100%;
 font-size: 30px;
 font-weight: bold;
 text-align: center;
 margin-bottom: 40px;
}
img{
  display: block;
  padding: 30px ;
  width: 100%;
  height: auto;
 } @media (max-width: 768px) {
    .name{
        font-size: 20px;
    }
 }
</style>
