<template>
  <div>
    <p class="name">新能源汽车 –车载电源OBC& DC-DC</p>
<el-image
        src="http://img.sicred.cn/assets/img/application/page32-1.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page32-1.png']"
        /><br/>
    目前OBC输出功率集中在3.3KW、6.6KW、11KW及22KW范围。
    11kW的OBC，意味着充满88kWh的电池（续航里程约650km-750km），需要8h。如果与燃油车加油的时间来对比，这个时间是漫长，因此提高充电速率是OBC的发展方向。在OBC上使用碳化硅功率器件对于提升OBC的充电速度，效率、功率密度发挥了重要作用。<br /><br />
    为缩短充电时间和提升动力电池电压，采用三相380V输入的模式可以将功率提升到12KW以上，PFC输出级的电压会相应提高到600V以上,故需选用1200V的SiC
    MOS和SiC SBD。因此，SiC MOS是未来12kW以上的OBC产品上的主要功率器件。<br /><br />
    <div class="name">车载电源OBC&DC-DC (PFC)</div>
    <el-image
        src="http://img.sicred.cn/assets/img/application/page32-2.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page32-2.png']"
        /><br/>
    第一个两路交错式PFC，常用于3.3KW单向OBC，为降低成本，D10&D20，会用到650V SiC
    JBS<br />
    第二个无桥PFC常用于6.6KW单向OBC，为降低成本，D1&D2，会用到650V SiC JBS
    <br />
    第三个无桥图腾柱PFC常用于6.6KW双向OBC，为提高能量转换效率，Q1~Q4会用到650V
    SiC MOS <br /><br />
    至信微建议用料规格:<br />
    &nbsp;&nbsp;● SiC SBD: 650V/10A~20A : SDC10065T2AS、SDC20065T2AS<br />
    &nbsp;&nbsp;● SiC MOSFET: 650V/40mΩ : SMC40N065T4AS<br />
    <el-image
        src="http://img.sicred.cn/assets/img/application/page32-3.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page32-3.png']"
        /><br/>
    左边维也纳PFC拓扑常用于单向11~22KW OBC，为降低成本，D1~D6，会用到1200V SiC JBS  <br />
右边全桥PFC拓扑常用于双向11~22KW OBC，为提高能量转换效率，会用到1200V SiC MOSFET <br /> <br />
至信微建议用料规格:<br />
    &nbsp;&nbsp;● SiC SBD: 1200V/20A~30A : SDC20120T2AA、SDC30120T2AS
    <br />
    &nbsp;&nbsp;● SiC MOSFET: 1200V/40mΩ & 80mΩ :SMC40N120T3AA 、SMC80N120T3AA<br />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.name {
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
img {
  display: block;
  padding: 30px;
  width: 100%;
  height: auto;
} @media (max-width: 768px) {
    .name{
        font-size: 20px;
    }
    img {
        padding: 10px 0;
    }
 }
</style>
