<template>
  <div>
    <p class="name">电动主驱逆变器</p>
    <el-image
        src="http://img.sicred.cn/assets/img/application/page34-1.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page34-1.png']"
        /><br/>
    主驱逆变器是电动汽车动力系统的心脏，其性能优劣直接影响到车辆的整体表现。主驱逆变器中的性能，主要体现在五个方面：<br />
    &nbsp;&nbsp;● 动力更强 - 更大的瞬间扭矩带来更多驾驶乐趣；<br />
    &nbsp;&nbsp;● 效率更高 - 航程更长，损耗更低；<br />
    &nbsp;&nbsp;● 电压更高 - 400V 电池是目前的主流，800V将是未来；<br />
    &nbsp;&nbsp;● 重量更轻 - 减轻车重，增加续航里程；<br />
    &nbsp;&nbsp;● 尺寸更小 - 可安装在前轴或后轴上，节省行李箱和后备箱空间。<br /><br />
    与硅基MOS和IGBT相比，SiC MOS 的优异特性，有效提升电能转化效率，延长续航里程，因而成为主驱逆变器设计的最优选择。<br /><br />
    至信微建议用料规格:<br />
    &nbsp;&nbsp;● SiC MOSFET: 1200V/7mΩ & 16mΩ : SMC190SE7N120MBH1、SMC70SE16N120MBH1
    <br />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.name {
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
img {
  display: block;
  padding: 30px;
  width: 100%;
  height: auto;
} @media (max-width: 768px) {
    .name{
        font-size: 20px;
    }
    img {
        padding: 10px 0;
    }
 }
</style>
