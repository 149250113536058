<template>
  <div>
    <p class="name">单相组串式逆变器（Inverter）应用</p>
    单相组串式主流Inverter拓扑是HERIC，可以实现高效能转换、体积小、重量轻、电磁干扰小且可靠性高等优点。在逆变器的设计和制造过程中，合理运用Heric拓扑可以帮助提升光伏逆变器的性能和质量。<br/>
H6拓扑，效率比HERIC拓扑低些，但共模特性更好。<br/>
储能PCS(功率 &lt; 10KW)，会用到2-level拓扑，功能是双向DC/AC，如：UPS、EPS<br/>
由于碳化硅器件的高频、高温及开关损耗低等优异特性，上述拓扑结构，都会有SiC MOSFET需求
<br/>
    <el-image
        src="http://img.sicred.cn/assets/img/application/page22-1.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page22-1.png']"
        /><br/>
    至信微建议用料规格:<br />
    &nbsp;&nbsp;● SiC MOSFET: 650V/25mΩ & 650V/40mΩ ：SMC25N065DT4AS、SMC40N065T4AS<br />
    <br />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.name {
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
img {
  display: block;
  padding: 30px;
  width: 100%;
  height: auto;
} @media (max-width: 768px) {
    .name{
        font-size: 20px;
    }
    img {
        padding: 10px 0;
    }
 }
</style>
