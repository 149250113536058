<template>
  <div>
    <p class="name">电动空调压缩机控制器</p>
    新能源汽车采用电动压缩机驱动空调制冷。由于纯电动汽车没有发动机，压缩机需要靠电力驱动，电动压缩机比传统压缩机多出了驱动电机、控制器等结构。空调制热方面，燃油汽车空调可借助发动机的余热。而新能源汽车制热通常是使用热泵空调。
    <br />
    <el-image
        src="http://img.sicred.cn/assets/img/application/page33-1.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page33-1.png']"
        /><br/>
    热泵空调电动压缩机控制器使用SiC MOSFET替代IGBT能够极大扩宽压缩机的性能，如：显著的能效和续航里程提升、更强的低转速控制能力、更强的高温工况带载启动能力、更适宜超低温热泵工况、带来NVH性能方面的提升、促进电动空调压缩机系统的小型化。
    <br/><br/>至信微建议用料规格:<br/>
    &nbsp;&nbsp;● SiC MOSFET: 1200V/40mΩ & 80mΩ :SMC40N120T3AA、SMC80N120T3AA
    <br/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
   
  },
  data () {
    return {
     
    }
  }
}
</script>

<style scoped>
.name{
  width: 100%;
 font-size: 30px;
 font-weight: bold;
 text-align: center;
 margin-bottom: 40px;
}
img{
  display: block;
  padding: 30px ;
  width: 100%;
  height: auto;
 } @media (max-width: 768px) {
    .name{
        font-size: 20px;
    }
 }
</style>
