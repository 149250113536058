<template>
  <div>
    <p class="name">直流充电桩电源模块 (PFC)应用</p>
    左边三相维也纳PFC拓扑常用于单向15KW左右的充电桩电源模块，会用到1200V的SiC SBD<br/>
右边三相全桥PFC拓扑常用于双向20KW左右的充电桩电源模块，会用到1200V的SiC MOSFET<br/>
<el-image
        src="http://img.sicred.cn/assets/img/application/page31-1.png"
        class="technologyImg"
        :preview-src-list="['http://img.sicred.cn/assets/img/application/page31-1.png']"
        /><br/>
    至信微建议用料规格:<br/>
    &nbsp;&nbsp;● SiC SBD: 1200V/20A~50A : SDC20120T2AA 、SDC50120T2AS<br/>
    &nbsp;&nbsp;● SiC MOSFET: 1200V/28mΩ & 32mΩ: SMC28N120T4AS 、SMC32N120T4BA<br/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
   
  },
  data () {
    return {
     
    }
  }
}
</script>

<style scoped>
.name{
  width: 100%;
 font-size: 30px;
 font-weight: bold;
 text-align: center;
 margin-bottom: 40px;
}
img{
  display: block;
  padding: 30px ;
  width: 100%;
  height: auto;
 } @media (max-width: 768px) {
    .name{
        font-size: 20px;
    }
 }
</style>
