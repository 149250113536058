<template>
  <div>
    <Header  :tabActive="3"/>
    <ScrollSmall :pageSmallTile="pageSmallTile" />
    <ApplicationContentNew />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header';
import ScrollSmall from '../components/ScrollSmall';
import ApplicationContentNew from '../components/application/Application-content-new';
import Footer from '../components/Footer';

export default {
  name: 'App',
  components: {
    Header,
    ScrollSmall,
    ApplicationContentNew,
    Footer
  },
  data () {
    return {
      pageSmallTile: {
        title: '产品应用',
        url: 'http://img.sicred.cn/assets/img/banner/application.png',
        tis: [
          {
            name:'APPLICATIONS',
            url:'#/application-solutions'
          }
        ]
      }
    }
  }
}
</script>

<style>
</style>
